import { CONTAINER_FILLING_PRINTER } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Printer", "search-by": "Name,Description", "ph-search-by": "Search by Name, Description", "has-delete": true, "has-active": false, "has-filter-inactive": false } }, [_c("text-field", { key: "Name", attrs: { "data-index": "name", "title": "Name", "width": 400, "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListPrinter"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListPrinter = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-printer" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "cf.label-printers", "api-url": _vm.apiUrl, "create-route": "/container-filling/label-printers/create", "edit-route": "/container-filling/label-printers/:id" } }, [_c("list-printer")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListPrinter
  },
  data() {
    return {
      page: CONTAINER_FILLING_PRINTER,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "label-printers",
          title: "Label Printers",
          path: "/container-filling/label-printers"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
